.account-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px;
  min-height: 100vh;
  @include main-gradient();

  .logo {
    width: 140px;
    margin: 0 auto;
  }

  .LanguageSelectorContainer {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.account-form {
  background-color: white;
  padding: 20px;
  width: 80%;
  border-radius: 5px;
  box-shadow: 0 0 24px rgba(0, 0, 0, .09);

  .title, .sub-text {
    text-align: center;
  }

  .title {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .sub-text {
    font-size: 14px;
    color: $gray;
    margin-top: 0;
  }
}

@include ob-breakpoint(m) {
  .account-form {
    width: 60%;
  }
}

@include ob-breakpoint(l) {
  .account-form {
    width: 40%;
  }
}
