.multi-column-list {
  display: flex;
  flex-wrap: wrap;

  .item {
    flex: 0 0 30%;
    margin-top: 0 !important;
    margin-bottom: 10px;
  }
}
