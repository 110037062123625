.border {
  position: relative;
  background-clip: padding-box;
  border: solid 2px transparent;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    @include main-gradient();
  }

  .sb-avatar__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 3px) !important;
    height: calc(100% - 3px) !important;
  }
}
