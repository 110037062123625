.ob-pagination {
  .uk-active {
    font-weight: bold;
    color: $default-color;
  }

  .uk-disabled {
    color: $gray !important;
  }

  li > a {
    &:hover {
      font-weight: bold;
      color: $default-color;
    }

    color: $gray;
  }
}
