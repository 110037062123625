.product-header {
  display: flex;
  align-items: center;

  .product-avatar {
    position: relative;

    .sb-avatar {
      border: 1px solid $main-blue;
    }
  }

  .product-name {
    flex-grow: 1;
    padding: 0 10px;
    font-size: 12px;
  }

  .product-options {
    margin-top: 10px;
  }

  .product-price {
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
  }

  .out-of-date {
    font-size: 18px;
    margin-left: 10px;
  }
}

.tarification-options-configuration {
  width: 50%;

  .tarification-form-option {
    margin-bottom: 10px;

    .option-label + .Select {
      width: 100%;
    }
  }
}

.option-label-field {
  display: flex;
  align-items: center;
}

.tarification-form-option {
  .option-label {
    display: flex;
  }

  .ob-icon {
    font-size: 16px;
    color: $openbroker-secondary;
  }

  .option-label + .Select {
    margin-left: auto;
  }

  .option-label-field {
    width: 100%;
  }
}

.tarification-configuration-options {
  width: 100%;
  flex-flow: column;

  .option-label {
    width: 400px;
  }

  .tarification-form-option {
    width: 100%;

    .Select {
      width: 200px;
    }

    .Select-control {
      margin-bottom: 0;
    }
  }
}

.tarification-table {
  margin-bottom: 1rem;

  .tarification-form-option {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  tr:not(.no-border),
  th {
    border: 1px solid rgb(241, 241, 241);

    .inner {
      display: flex;
      align-items: center;

      .ob-icon {
        margin-left: 10px;
        font-size: 18px;
        color: $openbroker-secondary;
      }
    }
  }

  tr.highlighted td {
    transition: all .2s;
    background: $main-orange-30;
  }

  .cover-price {
    width: 180px;
    text-align: right;

    &.price--unselected {
      .Bold {
        color: $openbroker-gray-light;
        font-weight: normal;
      }
    }
  }

  .cover-delete {
    width: 50px;
  }

  .Select {
    width: 80%;
  }
}

.tarification-show-table {
  margin-bottom: 1rem;

  tbody,
  tfoot,
  thead {
    border: 1px solid #f1f1f1;
  }

  tbody {
    tr {
      border-bottom: 1px solid #f1f1f1;
    }
  }

  .price-td {
    text-align: right;
    width: 100px;
  }

  .tarification-td {
    padding: 0;
  }

  .comment-td,
  .deductible-td {
    width: 1%;

    .material-icons {
      font-size: 18px;
      top: 3px;
    }
  }

  .deductible-td {
    white-space: nowrap;
    padding-right: 10px;
  }

  .action-tfoot {
    background-color: $openbroker-secondary-light;
  }

  span.out-of-date {
    font-size: 12px;
    color: $openbroker-warning;
    font-weight: normal;

    i {
      vertical-align: bottom;
      margin-right: 5px;
    }
  }

  tfoot {
    td {
      font-weight: normal;
    }
  }

  .Bold,
  strong {
    color: $openbroker-default-text;
  }
}

.tarification-alias-form {
  display: flex;
  align-items: flex-end;
  margin-bottom: 40px;

  button {
    height: 34px;
  }

  .input {
    width: 400px;
    margin-right: 10px;
  }
}

.tarification-option--vertical {
  display: flex;
  flex-flow: column;
}


.tarification-card {
  .cover-col {
    min-width: 50px;
  }
}

.tarification-card-title {
  display: flex;
  align-items: center;
  padding-right: 10px;
  flex-wrap: wrap;

  .tarification-computing {
    margin-left: auto;
  }
}

.tarification-computing {
  display: flex;
  align-items: center;

  .ob-spinner {
    transform: scale(.4);

    .SkChild {
      &:before {
        background-color: $openbroker-secondary;
      }
    }
  }
}

.drawer-inner .fixed-bottom {
  &.tarification-action-buttons {
    z-index: 0;
  }
}

.tarification-header-options {
  width: 400px;
  justify-content: flex-end;

  .Select {
    width: 100%;
    margin-right: 20px;
  }
}

.product-missing-data-button {
  color: white;
  background-color: $openbroker-warning;
  border: none;
  margin-left: 20px;

  &.hide {
    display: none;
  }

  &:hover,
  &:active,
  &:focus {
    color: white;
    outline: none;
    cursor: pointer;
  }
}

.uk-open {
  .product-missing-data-button {
    &.show {
      display: none;
    }

    &.hide {
      display: inline-block;
    }
  }
}

.tarification-table {
  .Select-control {
    margin-bottom: 0;
  }

  .cover-price {
    text-align: right;
  }

  .selected-option-button {
    .button__content {
      display: flex;
      align-items: center;
      line-height: 18px;
    }
  }

  .tooltip-icon {
    font-size: 16px;
    color: $openbroker-secondary;
    margin-left: 10px;
  }
}

.tarification-configuration-options {
  flex-wrap: wrap;

  .tarification-form-option {
    margin-right: 20px;
  }

  .Select-control {
    margin: 0;
    width: 220px;
  }
}

.extra-info-cover {
  &:hover {
    cursor: pointer;
    color: $main-blue;
  }

  .ob-icon {
    top: 2px;
  }
}
