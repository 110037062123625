.pricing-table-container {
  max-width: 750px;
  width: 100%;
}

.pricing-table {
  border: 1px solid $openbroker-secondary;
  display: flex;
  flex-direction: column;
}

.pricing-table * {
  text-align: center;
  text-transform: uppercase;
}

.pricing-plan {
  border-bottom: 1px solid #e1f1ff;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: column;
}

.pricing-plan:last-child {
  border-bottom: none;
}

.pricing-img {
  margin-bottom: 25px;
  max-width: 100%;
}

.pricing-header {
  color: $openbroker-primary;
  font-weight: 600;
  letter-spacing: 1px;
}

.pricing-features {
  color: $openbroker-secondary;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 50px;
  margin-bottom: auto;
  padding: 0;
  list-style: none;
}

.pricing-features-item {
  border-top: 1px solid #e1f1ff;
  font-size: 12px;
  line-height: 1.5;
  padding: 15px 0;
}

.pricing-features-item:last-child {
  border-bottom: 1px solid #e1f1ff;
}

.pricing-price {
  color: $openbroker-secondary;
  display: block;
  font-size: 32px;
  font-weight: 700;
}

.pricing-bottom {
  margin-top: 50px;
}

.pricing-price-addition {
  color: $openbroker-gray;
  font-size: 10px;
}

strong {
  color: $openbroker-secondary;

  &.current {
    color: $openbroker-primary;
  }
}

@media (min-width: 900px) {
  .pricing-table {
    flex-direction: row;
  }

  .pricing-plan {
    border-bottom: none;
    border-right: 1px solid #e1f1ff;
    padding: 25px 50px;
    width: 250px;
  }

  .pricing-plan:last-child {
    border-right: none;
  }
}
