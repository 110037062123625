.file-item {
  @extend .object-card;
  display: flex;
  border-radius: 0;
  color: $main-blue;
  border: 1px solid;
  width: 200px;
  position: relative;
  text-overflow: ellipsis;
  align-items: center;
  padding: 10px;


  &:hover {
    cursor: pointer;
    color: $openbroker-secondary;
  }

  &.file--success {
    overflow: hidden;
  }

  .remove-file,
  .delete-file {
    background-color: $light-gray;
    border-radius: 50%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .3);
    color: $main-blue;
    height: 30px;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(20%, -20%);
    width: 30px;

    .ob-icon {
      font-size: 14px;
      top: 1px;
    }
  }

  .delete-file {
    background-color: $main-red;
    color: $white;
  }

  .file-upload-success {
    background-color: $main-green;
    border-radius: 0 0 0 5px;
    color: $white;
    padding: 5px;
    position: absolute;
    right: 0;
    top: 0;

    .material-icons {
      font-size: 12px;
    }
  }

  .file-name {
    margin-left: 10px;
    font-size: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: auto;

    &.full--name {
      overflow: auto;
      white-space: normal;
    }
  }

  .file-icon {
    .ob-icon {
      top: 2px;
      font-size: 18px;
    }
  }

  .file-name,
  .file-icon {
    margin-top: 0;
    margin-bottom: 0;
  }

  .download-icon {
    margin-left: 10px;

    .ob-icon {
      font-size: 18px;
      top: 2px;
      color: $openbroker-gray;
    }
  }

  .uk-button {
    display: flex;
    padding: 0;
    margin-left: 10px;
    background-color: transparent;
  }
}

.file-list {
  display: flex;

  li > a {
    display: inline-block;
  }
}
