@mixin main-gradient() {
  background-image: radial-gradient(circle farthest-corner at 12.3% 19.3%, rgba(85, 88, 218, 1) 0%, rgba(95, 209, 249, 1) 100.2%);
}

@mixin ob-breakpoint($point) {
  @if $point == s {
    @media (min-width: 640px) {
      @content;
    }
  } @else if $point == m {
    @media (min-width: 960px) {
      @content;
    }
  } @else if $point == l {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $point == xl {
    @media (min-width: 1600px) {
      @content;
    }
  }
}
