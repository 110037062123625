.subscription-required-panel {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding: 40px 0;

  img {
    max-width: 40%;
    margin: 40px 0;
  }
}
