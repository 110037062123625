.office-address-list {
  display: flex;
  flex-wrap: wrap;

  .office-address {
    margin-right: 20px;
    margin-bottom: 20px;
  }
}

.office-address {
  width: 300px;

  &:hover {
    cursor: pointer;
  }
}

.primary-address-icon {
  font-size: 18px !important;
  color: $main-yellow;
}

.logo-container {
  border: 1px solid $main-blue;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
}
