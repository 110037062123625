.button-group,
.btn-group {
  display: flex;
  align-items: center;
  flex-flow: column;

  &.last-right {
    & > *:last-child {
      margin-left: auto;
    }
  }

  .btn {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include ob-breakpoint(m) {
    flex-flow: row;

    .btn {
      margin: 0 10px 0 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;

  .btn-tiny:only-child {
    max-width: 200px
  }

  .btn-tiny:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .btn-tiny:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &.group-expanded {
    flex: 1;

    .btn-tiny {
      flex: 1;
      justify-content: center;
    }
  }
}


.inline-button {
  display: inline !important;
}

.button-search {
  background-color: #4f68a0;
  border-radius: 30px;
  color: white;
  transition: all .2s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  line-height: 40px;
  position: relative;

  .ob-icon {
    margin-right: 10px;
  }

  &:hover {
    background-color: darken(#4f68a0, 10%);
  }
}

.search-link {
  display: flex;
  align-items: center;

  .ob-icon {
    margin-right: 10px;
  }

  &:hover {
    text-decoration: none;
  }
}

.uk-button {
  &:not(.uk-button-default) {
    &,
    &:hover {
      border: 0;
    }
  }

  &.icon-only-button {
    padding: 0;
  }

  &.round-button {
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  &.button-inverted {
    .ob-icon {
      top: 2px;
    }

    &.uk-button-primary {
      color: $global-primary-background;
      background: transparent;
    }

    &.uk-button-secondary {
      color: $global-secondary-background;
    }

    &.uk-button-danger {
      color: $global-danger-background;
      background: transparent;
    }
  }

  &.btn-tiny {
    padding: 0 10px;

    .icon-text {
      .icon {
        margin-left: 0;
        margin-right: 5px;
        font-size: 14px;
      }

      font-size: 9px !important;
    }
  }

  &.secondary-light {
    color: $main-blue-80;
    border: 0;
    border-radius: 5px;

    &:hover {
      color: $main-blue;
    }
  }

  .loading-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }

  &.uk-button-large {
    .loading-content {
      padding: 10px;
    }
  }
}


.submit-button-content {
  text-align: center;
}

.has-remove-button {
  position: relative;

  button {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }
}

.button-transparent {
  display: flex;
  background: transparent;
}
