.space-left {
  padding-left: 5px;
}

.margin-top {
  margin-top: 10px;
}

.margin-left {
  margin-left: 20px !important;
}

.margin-bottom {
  margin-bottom: 10px;

  &.margin--large {
    margin-bottom: 30px;
  }
}

.margin-right {
  margin-right: 10px;
}

.no-margin {
  margin: 0;
}

.no-padding-right {
  padding-right: 0;
}

.no-padding {
  padding: 0;
}

.success {
  color: $main-green;
}

.error {
  color: $main-red !important;
}

.warning {
  color: $main-orange;

  strong {
    color: $main-orange !important;
  }
}

a.disabled,
.disabled {
  color: $gray;

  &:hover {
    color: $gray !important;
  }
}

.bold-on-hover {
  // Trick to prevent move effect when font is in bold only on hover
  // It is using title attribute, which should contain the same as the label
  &::after {
    color: transparent;
    content: attr(title);
    display: block;
    font-weight: bold;
    height: 1px;
    margin-bottom: -1px;
    overflow: hidden;
    visibility: hidden;
  }
}

.text-left {
  text-align: left;
}

.align-top,
.v-top {
  vertical-align: top;
}

.align-middle,
.v-middle {
  vertical-align: middle;
}

.first-uppercase::first-letter {
  text-transform: uppercase;
}

.bold {
  color: $main-blue;
  font-weight: bold;
}

.small-italic {
  color: $gray;
  font-size: 12px;
  font-style: italic;

  &.error {
    color: $main-red;
  }
}

.small {
  font-size: 12px;
}

.green {
  color: $main-green;
}

.hidden {
  display: none;
}
