.wizard-wrapper {
  //overflow: hidden;
}

.wizard-container {
  display: flex;
  background-color: #EBEDF5;
}

.wizard-step {
  flex: 1;
  padding: 10px 20px;
  background-color: $white;
  position: relative;
}

.wizard-navigation-container {
  background-color: #EBEDF5;
  margin: 0;
  position: relative;
  z-index: 10;

  .loader {
    align-self: center;
    display: flex;
    align-items: center;
    flex-flow: column;
    font-style: italic;
    color: $gray;
    padding: 20px;
    text-align: center;

    .ob-icon {
      margin: 0 0 20px 0;
    }

  }

  .wizard-navigation-product-header {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #61677f;

    .product-logo {
      margin-right: 10px;
    }

    .title {
      font-weight: bold;
      font-size: 14px;
      color: $white;
      width: 100%;
    }
  }

  .wizard-navigation-product-header + .wizard-navigation {
    margin-top: 0;
    padding-top: 0;
  }
}

.wizard-navigation {
  padding: 20px 0;
  list-style: none;

  .wizard-navigation {
    padding: 0 0 0 20px;

    .wizard-step-nav {
      border: 0;
      padding: 10px 20px;

      &.active,
      &:hover {
        border: 0;
        font-weight: bold;
      }
    }
  }
}

.step-badge {
  width: 30px;
  display: inline-block;
  background: $main-blue;
  border-radius: 20px;
  color: $white;
  text-align: center;
  margin-left: 20px;
  font-size: 10px;
}

.wizard-step-nav {
  margin: 0;

  padding: 10px 20px;
  border-left: 2px solid transparent;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  color: $default-color;

  .ob-icon:not(.warning),
  .ob-icon:not(.information) {
    margin-right: 10px;
    color: $gray;
  }

  .warning-icon {
    margin-left: 10px;
  }

  .wizard-step-addition {
    display: flex;
  }

  &.active,
  &:hover {
    cursor: pointer;

    & > .ob-icon:not(.warning),
    & > .ob-icon:not(.information) {
      color: $main-blue;
    }

    border-color: $main-blue;
    color: $main-blue;
    font-weight: bold;
  }

  &.disabled {
    color: $gray;

    &:hover {

      border-color: transparent;
      color: $gray;
      font-weight: normal;

      & > .ob-icon:not(.warning),
      & > .ob-icon:not(.information) {
        color: $gray;
      }

    }
  }
}

.wizard-step-title {
  color: $main-blue;
  text-align: center;
  font-size: 20px;
  margin: 20px 0 40px;
  font-weight: bold;
}

a.wizard-button,
.wizard-button {
  display: flex;
  align-items: center;
  background-color: $main-blue;
  color: $white;
  height: 50px;
  padding: 0 30px;
  border: 0;
  border-radius: 2px;
  outline: none;
  text-transform: uppercase;

  &.primary {
    background-color: $main-green;
  }

  &:hover {
    cursor: pointer;
    color: $white;
  }

  &:disabled,
  &.disabled {
    background-color: $main-blue-60;
    cursor: not-allowed;
    opacity: .4;
  }

  .ob-icon:not(.loading-icon) {
    margin-left: 5px;
  }

  &.previous {
    background-color: transparent;
    color: $gray;

    .ob-icon {
      margin-left: 0;
      margin-right: 5px;
    }
  }
}

.wizard-button-container {
  margin: 60px 0;
  display: flex;
  justify-content: center;
}

.step-subscription-warning,
.loading-required-fields {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-required-fields {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .1s, opacity .1s ease-in;

  &.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity .1s ease-in;
  }

  &:after {
    content: '';
    background-color: rgba(220, 220, 220, .4);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .loading-message {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    //background: rgba(0, 0, 0, .3);
    padding: 20px;
    color: $default-color;
    z-index: 20;

    .ob-icon {
      margin-bottom: 20px;
    }
  }
}

.step-subscription-warning {
  background-color: rgba(255, 255, 255, .8);

  .subscription-required-panel {
    background-color: transparent;
  }
}

.grayscale {
  transition: filter .1s ease-in;
  filter: grayscale(1);
}

.cr-wizard-resume {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    width: 50%;
    margin: 0 0 10px 0;
  }
}

@include ob-breakpoint(l) {
  .wizard-step {
    padding: 20px 40px;
  }

  .wizard-navigation {
    width: 330px;

    .wizard-navigation {
      width: auto;
    }
  }

  .wizard-step-nav {
    a {
      padding: 20px 40px;
    }
  }
}

.wizard-warning-tooltip {
  width: 330px;
  font-weight: normal !important;
}

.warning-tooltip-content {
  max-height: 330px;
}
