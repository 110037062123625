.spinner {
  margin: 0 auto;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: $main-blue-80;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
}

.main-loader-container {
  background-color: rgba(0, 0, 0, .6);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100000;

  .spinner > div {
    background-color: $white;
  }

  .loader-message {
    color: $white;
    font-style: italic;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
  }
}

.sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  &:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
    animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  }
}

.sk-circle {
  width: 40px;
  height: 40px;
  position: relative;

  @-webkit-keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
  }

  @keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
  }

  .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg); }
  .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg); }
  .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg); }
  .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg); }
  .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg); }
  .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }
  .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg); }
  .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg); }
  .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg); }
  .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg); }
  .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg); }
  .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s; }
  .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s; }
  .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s; }
  .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s; }
  .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s; }
  .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s; }
  .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s; }
  .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s; }
  .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s; }
  .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s; }
  .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s; }
}
