.risk-object-card-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 80%;
  margin: 0 auto;

  .risk-object-button-card {
    margin: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.risk-object-button-card {
  width: 150px;
  height: 150px;
  border-radius: 5px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  font-size: 14px;
  border: 1px solid $gray;
  outline: none;
  position: relative;

  .icon {
    font-size: 40px;
    margin-bottom: 10px;
  }

  .icon-selected {
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    top: 9px;
    right: 9px;
  }

  &:hover,
  &.selected {
    cursor: pointer;
    color: $main-blue;
    border-color: $main-blue;
    background-color: $white;
  }

  &:disabled {
    background-color: $light-gray;

    &:hover {
      cursor: default;
      color: $gray;
      border-color: $gray;
      background-color: $light-gray;
      font-weight: normal;
    }
  }
}
