.uk-table-small td.loader-row {
  padding-bottom: 0;
  padding-top: 0;
  vertical-align: middle;
}

.product-col {
  vertical-align: top !important;
}

.checkbox-col {
  width: 1%;
  vertical-align: middle !important;

  input[type="checkbox"] {
    margin: 0;
  }
}

.price-col {
  text-align: center;
  //width: 150px;
}

.tarification-name {
  .ob-icon {
    cursor: pointer;
    font-size: 16px;
    margin-left: 5px;
    color: $openbroker-secondary;
  }
}

.price-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  white-space: nowrap;

  .ob-icon {
    cursor: pointer;
    font-size: 16px;
    margin-left: 5px;
    color: $openbroker-secondary
  }

  &.total-price {
    flex-direction: column;
    align-items: flex-end;
  }

  .staggering-price {
    font-size: 12px;
    font-weight: normal;
    font-style: italic;
    color: $openbroker-gray;
  }
}

.tarification-button-cell {
  padding: 10px 0 !important;

  table {
    td {
      color: transparent;
      text-shadow: 0 0 9px rgba(0, 0, 0, 0.5);
      -webkit-user-select: none; /* Chrome all / Safari all */
      -moz-user-select: none; /* Firefox all */
      -ms-user-select: none; /* IE 10+ */
      user-select: none; /* Likely future */
    }
  }

  .tarification-button-container {
    position: relative;

    td {
      height: 60px;
    }

    .btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.quick-tarifications-table,
.tarification-product-error {
  tbody tr, thead tr {
    &.with-border-bottom {
      border-bottom: 1px solid #e5e5e5;
    }
  }

  tbody tr {
    height: 60px;

    &.footer-row {
      height: 30px;

      td {
        padding-top: 0;

        span.text {
          line-height: 30px;
        }
      }
    }
  }

  .uk-accordion {
    margin: 0;
    font-size: 12px;
    padding: 10px;
  }

  .uk-accordion-title {
    text-align: left;
    font-size: 12px;
  }

  .uk-alert-warning {
    color: $openbroker-warning;

    .uk-accordion-title {
      color: $openbroker-warning;
    }
  }

  .uk-alert-danger {
    color: $openbroker-error;

    .uk-accordion-title {
      color: $openbroker-error;
    }
  }

  .warning-message {
    max-width: 100% !important;
    width: 90%;
    margin: 0 auto;
  }
}
