.drawer-inner {
  padding: 40px;
  height: 100vh;
  box-sizing: border-box;
  overflow-y: scroll;

  &.has-fixed-bottom {
    padding-bottom: 100px;
    #drawer-content {
      padding-bottom: 100px;
    }
  }

  .fixed-bottom {
    position: absolute;
    z-index: 100;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px 0;
    background-color: white;
    width: 100%;
    justify-content: center;
    box-shadow: 0 -1px 3px rgba(0, 0, 0, .2);
    @extend .top-border;

    &:empty {
      display: none;
    }
  }

  .construction_delay_delete {
    position: absolute;
    right: 0px;
    top: 0;
  }
}
