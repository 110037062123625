.document-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-flow: wrap;

  li {
    margin-right: 10px;
  }
}
