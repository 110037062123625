.top-border,
.bottom-border {
  position: relative;

  &:before {
    content: '';
    height: 2px;
    width: 100%;
    position: absolute;
    left: 0;
    background-image: radial-gradient(circle farthest-corner at 12.3% 19.3%, rgba(85, 88, 218, 1) 0%, rgba(95, 209, 249, 1) 100.2%);
  }
}

.top-border {
  &:before {
    top: 0;
  }
}

.bottom-border {
  &:before {
    bottom: 0;
  }
}


.block-title {
  @extend .uk-text-muted !optional;
  @extend .uk-text-uppercase !optional;
}

.block {
  background-color: $directory-background;
  border: 1px solid $directory-border;
}

.block-title-background {
  font-size: 16px;
  color: white;
  background-image: radial-gradient(circle farthest-corner at 12.3% 19.3%, rgba(85, 88, 218, 1) 0%, rgba(95, 209, 249, 1) 100.2%);
  padding: 10px;
}

.block.p-20 {
  .block-title-background {
    margin-top: -20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}

.block-content-center {
  display: flex;
  align-items: center;
  height: 100%;
}
