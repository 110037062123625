table {
  border-collapse: collapse;
  width: 100%;

  tfoot,
  thead {
    background-color: #f8f8f8;
    color: #0a0a0a;
  }

  tfoot,
  thead {
    td,
    th {
      padding: .5rem .625rem .625rem;
      text-align: left;
    }
  }

  tbody {
    td,
    th {
      padding: .5rem .625rem .625rem;
    }
  }

  tr {
    &.active {
      background: $openbroker-green-light !important;
    }

    &.selectable {
      cursor: pointer;
    }

    .display-on-hover {
      opacity: 0;
      transition: opacity .1s;
    }

    &:hover {
      .display-on-hover {
        opacity: 1;
        pointer-events: initial;
      }
    }
  }
}

.table-fixed-header {
  thead th {
    position: sticky;
  }
}

.smallest {
  max-width: 1%;
  width: 1%;
  white-space: nowrap;
}

.folder-table {
  td,
  th {
    vertical-align: middle;

    &:first-child,
    &:last-child {
      padding: 10px;
    }
  }

  tr {
    cursor: pointer;
  }

  th {
    white-space: nowrap;

    .ob-icon {
      font-size: 12px !important;
    }
  }
}

.lead-table.uk-table-hover tbody tr.active-lead {
  td {
    color: $openbroker-gray-light;
    font-style: italic;
  }

  &:hover {
    background-color: transparent;
  }
}

.folder-table-container {
  background-color: $white;
}

.folder-filter {
  display: inline;
  cursor: pointer;

  [class^="theme--text--"] {
    font-size: 12px;
  }
}


.tarification-table {
  .tarification-form-option {
    .option-label-field {
      flex-flow: column;

      .Select,
      .option-label {
        flex-flow: column;
        width: 100%;
      }

      .option-label {
        margin-bottom: 10px !important;
      }
    }
  }
}

@include ob-breakpoint(l) {
  .tarification-table {
    .tarification-form-option {
      .option-label-field {
        flex-flow: row;

        .option-label {
          width: 140px;
          min-width: 140px;
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

