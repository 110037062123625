.person-bms {
  padding: 10px;
  border: 1px solid $openbroker-secondary;
  display: flex;
  align-items: center;

  .data {
    width: 100%;
  }

  p {
    margin: 0;
  }

  .name {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .actions {
    button {
      line-height: 15px;
      padding: 10px;
    }
  }
}
