.full-width {
  width: 100%;
}

.Select {
  font-weight: normal;
}

.Select-control {
  border-radius: 0;
  height: 30px;

  .Select-input {
    height: 30px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .Select-value-label {
    line-height: 30px;
    font-size: .875rem;
  }
}

.Select-menu,
.Select-option {
  font-size: .875rem;
}

.Select-option {
  height: 30px;
}

.Select-placeholder, .Select--single > .Select-control .Select-value {
  line-height: 30px;
}

.uk-radio,
.uk-checkbox {
  margin-right: 10px;
}

.input-label {
  display: block;
  margin-bottom: 10px;

  .tooltip-icon {
    margin-left: 5px;

    &.material-icons {
      top: 4px;
    }
  }

  .material-icons {
    font-size: 18px;
    top: 3px;
    margin-right: 5px;

    &:not(.warning):not(.error) {
      color: $openbroker-gray;
    }
  }
}

.uk-input:focus,
.uk-select:focus,
.uk-textarea:focus,
.uk-checkbox:focus,
.uk-radio:focus {
  border-color: $openbroker-secondary;
}

.uk-checkbox:checked,
.uk-checkbox:indeterminate,
.uk-radio:checked {
  &:focus {
    background-color: $openbroker-secondary;
  }

  background-color: $openbroker-secondary;
}

.range-slider-values {
  margin-bottom: 25px !important;
}

.domain-name-symbol {
  padding-right: 120px !important;

  & + .uk-form-icon {
    width: 120px !important;
  }
}

.ob-margin {
  margin-bottom: 20px;
}

form {
  .input-label {
    font-weight: bold;
  }
}

.Select-value-label {
  font-weight: normal;
}

.rdw-editor-wrapper {
  .rdw-editor-main {
    overflow: visible;
  }
}

.form-title-separator {
  margin: 20px 0;
}

.submit-button-container {
  &.sticky {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background: white;
    padding: 20px;
  }
}

.autocomplete-container {
  position: relative;

  .autocomplete-dropdown-container {
    &:empty {
      display: none;
    }

    position: absolute;
    background-color: $openbroker-gray-light;
    left: 0;
    right: 0;
    padding: 10px;

    .suggestion-item {
      padding: 5px;
    }

    .suggestion-item--active {
      padding: 5px;
      background-color: $openbroker-secondary-light;
      cursor: pointer;
    }
  }
}

.company-form {
  padding-bottom: 20px;
}

.form-inline {
  display: flex;
}

.SearchBoxContainer {
  .extension {
    button {
      background: none;
      border: 0;
      display: flex;
    }
  }
}

.Select {
  .Select-option {
    height: auto !important;
  }
}

.switch-input-spinner {
  width: 25px;
  height: 25px;

  .SkChild:before {
    background-color: $openbroker-secondary;
  }
}

.symbol-input {
  padding-right: 30px !important;
}

.symbol-container {
  position: relative;
  display: block;

  .ob-icon {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 10px;

    &:hover {
      cursor: pointer;
    }
  }
}

.inline-fields {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &.top {
    align-items: flex-start;
  }

  & > label {
    width: 150px;
    min-width: 150px;
    margin-right: 20px;
    display: flex;
    align-items: center;

    .ob-icon-warning {
      margin-left: 10px;
    }
  }

  & > label + label {
    width: 100%;

    input {
      min-width: 18px;
    }
  }

  .Select {
    min-width: 300px;
  }

  .multi-column-list {
    margin: 0;
    padding-left: 0;
  }

  .uk-inline,
  .input-container {
    max-width: 300px;

    &.uk-width-1-1 {
      max-width: 100%;
    }

    .uk-inline {
      width: 100%;
    }
  }

  .address-form {
    .uk-inline,
    .input-container {
      max-width: 100%;
    }
  }

  .field {
    width: 100%;
  }

  .range-slider {
    z-index: 0;
  }

  .uk-input:not(.autocomplete-input) {
    height: 33px !important;
    border-radius: 2px;
  }
}

[type="button"] {
  border-radius: 0;
}

.form-error-message {
  align-self: center;
  margin-left: 10px;
}

.input-container {
  width: 100%;

  .form-error-message {
    margin-left: 0;
    font-size: 10px;
    padding: 3px 10px;
    background-color: $main-red;
    color: $white !important;
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
  }
}

.tarification-form {

  .Select {
    min-width: 300px;
  }

  &:after {
    content: attr(data-loading-message);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(80, 80, 80, .8);
    border-radius: 5px;
    color: $white;
    font-size: 14px;
    font-style: italic;
    display: none;
    padding: 10px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: not-allowed;
    background-color: rgba(199, 199, 199, 0.5);
    display: none;
    justify-content: center;
    align-items: center;
  }

  &[data-disable="disabled"] {
    filter: grayscale(1);
    position: relative;

    &:after,
    &:before {
      display: block;
      z-index: 10;
    }
  }
}


.tarification-header-options {
  .btn {
    .button__content {
      .ob-icon {
        margin: 0;
      }
    }
  }
}

.tarification-form-option .option-label {
  min-width: 110px;

  .input-label {
    white-space: initial !important;
  }
}

.inline-checkbox {
  display: flex;
  flex-flow: wrap;

  label {
    margin-right: 10px;

    &:first-child {
      margin-bottom: 10px;
    }
  }
}

.uk-button {
  &:disabled {
    cursor: not-allowed;
  }
}

.uk-button-primary {
  &:disabled {
    background-color: $gray;
    color: $white;
  }
}

.uk-radio {
  &:disabled {
    cursor: not-allowed;

    &:checked {
      background-color: $light-gray;
    }
  }
}

.table-field {
  .Select {
    width: auto !important;
    min-width: auto !important;
  }

  .SingleDatePickerInput {
    min-width: 140px;
  }
}

@include ob-breakpoint(l) {
  .inline-fields {
    & > label {
      width: 350px;
      min-width: 350px;
      margin-right: 20px;
    }
  }
  .modal-content {
    .inline-fields {
      flex-flow: column;
      align-items: flex-start;

      & > label {
        width: auto;
        min-width: auto;
        margin-bottom: 10px;
        font-weight: bold;
      }
    }
  }
}

.autocomplete-input {
  background-color: rgb(250, 250, 250);
  width: 100%;
  box-sizing: border-box;
  padding: 10px 15px !important;
  border-radius: 20px;
  border: 1px solid rgb(230, 230, 230);
}


.month-year-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33px;
  top: -5px;
  position: relative;

}

.SingleDatePickerInput {
  .SingleDatePickerInput_calendarIcon {
    padding: 0 10px 0 10px;
    margin: 0;

    .ob-icon {
      top: 1px;
    }
  }

  .DateInput_input {
    font-size: 12px;
  }

  .DateInput_input__focused {
    border-color: $main-blue;
  }

  .CalendarDay__selected {
    background-color: $main-blue;
    border-color: $main-blue;
  }

  .SingleDatePickerInput_calendarIcon,
  .SingleDatePickerInput_clearDate {
    outline: none;
  }
}

.uk-form-danger {
  .SingleDatePickerInput {
    border-color: $main-red;
  }
}

.input-container {
  &.uk-form-danger {
    .SingleDatePicker + .form-error-message {
      display: table-cell;
    }
  }
}

.autocomplete-result-list {
  .suggestion-item {
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid $gray;
    cursor: pointer;
    display: flex;
    align-items: center;

    &.active {
      border-color: $main-blue;
      color: $main-blue;
      font-weight: bold;
    }
  }
}

.confirm-primary {
  background-color: $main-green;
  color: $white !important;

  &:hover {
    background-color: $main-green !important;
    color: $white !important;
  }
}

.DateInput_input {
  &:focus {
    outline: none;
  }
}

.date-input {
  min-width: 150px;
}

.field-suggestions {
  position: relative;

  .suggestion-list {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: $white;
    padding: 10px;
    transform: translateY(100%);
    z-index: 100;
    border: 1px solid $main-blue;
    box-sizing: border-box;

    .suggestion-item {
      &.active {
        cursor: pointer;
        font-weight: bold;
      }
    }

    &:empty {
      display: none;
    }
  }
}

.select-button-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  padding-left: 0;
  margin: 0;

  li {
    margin: 0 10px 0 0;

    &:last-child {
      margin-right: 0;
    }
  }
}

.select-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 40px;
  border: 1px solid $main-blue;
  border-radius: 3px;
  position: relative;
  text-align: center;

  input {
    display: none;
  }

  &.small-button {
    padding: 5px;
  }

  .ob-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    margin-right: 10px;
  }

  &.selected,
  &:hover {
    .ob-icon {
      opacity: 1;
    }

    background-color: $main-blue;
    color: $white;
    cursor: pointer;
  }
}

.label-column {
  flex-flow: column;
  align-items: flex-start !important;
}

.button-list {
  display: flex;
  align-items: center;
  flex-flow: wrap;

  .item {
    margin: 0 0 10px 0 !important;
  }

  .select-button {
    margin-right: 10px;
  }
}

.comment-full-width {
  .input-container {
    width: 100%;
    max-width: 100%;
  }
}

.axa-building-build-year {
  display: flex;
  max-width: 100% !important;
  align-items: center;
}

.build-year-or-decade {
  display: flex;
  align-self: center;
  margin: 0;
  padding: 0 10px;
}
