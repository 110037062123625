html {
  background: $body-background-color-provider;
}

body, html {
  margin: 0;
  background-color: $body-background-color-provider;
  min-height: 100%;
  height: 100%;
}

.ob-content {
  padding: 20px;
}

body.content-no-padding {
  .ob-content {
    padding: 0;
  }
}

.has--environment-banner,
body[data-env-banner="has--environment-banner"] {
  padding-top: $banner-height;
}

