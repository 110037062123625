.close-modal-button {
  background-color: transparent;
  border: 0;

  &:hover {
    cursor: pointer;
  }
}

.ob-modal {
  width: 100%;
  transform: translate(0, 0);
  background-color: white;
  outline: none;

  .ob-modal-title {
    margin: 0;
  }

  @include ob-breakpoint(m) {
    position: absolute;
    max-height: 90vh;
    width: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &.large {
      width: 80%;
    }
  }
}

.ob-modal-content {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  max-height: 70vh;

  .action-bar,
  .fixed-bottom {
    @extend .top-border;
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px 0;
    margin: 0;
  }
}

.ob-modal-title {
  position: sticky;
  top: 0;
  padding: 20px;
  background-color: white;
  z-index: 10;
  margin: 0;

  .ob-title {
    font-size: 16px;
    font-weight: bold;
  }
}


.ReactModal__Overlay {
  z-index: 10000;
}
