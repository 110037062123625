$integration-size: 150px;

.product-card-list {
  display: flex;
  flex-wrap: wrap;

  .product-card-item {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  &.centered {
    justify-content: center;

    .product-card-item {
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}

.product-card-item {
  width: $integration-size;
  height: $integration-size;
  display: block;
  border: 1px solid #E3E8EC;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  padding-bottom: 60px;
  box-sizing: border-box;

  .integration-status {
    z-index: 10;
    font-size: 12px;
  }

  .product-card-icon {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 36px;
  }

  img {
    position: absolute;
    top: 45px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;

    &.greyscale {
      filter: grayscale(1);
    }
  }

  .product-card-name {
    border-top: 1px solid #E3E8EC;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    font-weight: bold;
    height: 60px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background-color: white;
    overflow: hidden;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &.selected {
    border-color: $main-blue;
    position: relative;

    .product-card-name {
      background-color: $main-blue;
      color: #fff;
    }

    .icon-selected {
      position: absolute;
      right: 7px;
      top: 7px;
      background-color: $main-blue;
      color: #fff;
      border-radius: 50%;
      padding: 5px;
    }

    &:hover {
      .product-card-name {
        color: #fff;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;

    &:hover {
      cursor: not-allowed;
      border-color: $directory-border;

      .product-card-name {
        color: $gray;
      }
    }
  }

  &:hover {
    cursor: pointer;
    border-color: $openbroker-secondary;

    .product-card-name {
      color: $openbroker-secondary;
    }
  }

  &.coming_soon {
    position: relative;
    overflow: hidden;

    .icon-container {
      background-color: $openbroker-warning;
    }
  }

  &.down {
    position: relative;
    overflow: hidden;

    .icon-container {
      background-color: $openbroker-gray;
    }
  }

  &.with_discount {
    position: relative;
    overflow: hidden;
    border-color: $main-yellow;

    .icon-container {
      background-color: $main-yellow;
      right: auto;
      left: 0;
      border-top-right-radius: 2px;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 5px;
    }
  }

  .icon-container {
    position: absolute;
    top: 0;
    right: 0;
    color: $white;
    padding: 10px;
    border-top-right-radius: 2px;
    border-bottom-left-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.loading {
    .loader-container {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, .2);
      display: flex;
      justify-content: center;
      align-items: center;

      .spinner {
        & > div {
          background-color: $white;
        }
      }
    }
  }
}

.product-card-list-title {
  img {
    width: 40px;
  }
}

$product-logo-size: 20px;
.product-tiny-list {
  .product-logos {
    display: inline-flex;
    flex-direction: row-reverse;
    padding-left: $product-logo-size/2;
    align-items: center;
  }

  .sb-avatar {
    margin-left: -$product-logo-size/2;
    position: relative;
    border: 1px solid $gray;
    overflow: hidden;
  }
}

.rest-product {
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  height: $product-logo-size;
  padding: 0 5px;
  background-color: $main-blue-80;
  border-radius: $product-logo-size;
  font-size: 8px;
  margin-left: 5px;
}

.product-name {
  font-size: 10px;
  margin-left: 5px;
}

.product-logo {
  .sb-avatar {
    overflow: hidden;

    img {
      transform: scale(1.1)
    }
  }
}
