.folder-tabs {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  font-weight: 300;
  font-size: 1.25em;

  ul {
    align-items: flex-end;
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      position: relative;
      z-index: 1;
      display: block;
      margin: 0;
      text-align: center;
    }

    .tab--disabled {
      opacity: .5;

      .tab {
        &:hover {
          cursor: not-allowed;
        }
      }
    }

    .tabs-title {

      & > a {
        bottom: -3px;
      }

      &:focus,
      & > a:focus {
        outline: 0;
      }

      &.is-active {
        a:after {
          background-color: white;
        }
      }

      .tab {
        padding: 20px 40px 20px 20px;
        line-height: 20px;
        text-align: left;
        top: 1px;

        span {
          display: inline;
        }
      }
    }

    .tab {
      padding: 5px 40px 5px 20px;
      position: relative;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 2.5;
      color: $provider-color;
      transition: color 0.2s;
      font-size: 1rem;
      top: 1px;

      &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: -2px;
        left: 0;
        z-index: -1;
        outline: 1px solid transparent;
        border-radius: 10px 10px 0 0;
        background: $light-gray;
        content: '';
        transform: perspective(5px) rotateX(0.93deg) translateZ(-1px);
        transform-origin: 0 0;
        backface-visibility: hidden;
      }

      &.active {
        &:after {
          background-color: $white;
        }
      }
    }
  }
}

.folder-tab-container {
  background-color: $white;
  padding: 20px;
}
