.warning-message {
  background-color: $main-orange-50;
  border: 1px solid $main-orange;
  border-radius: 5px;
  color: $main-orange;
  font-weight: bold;
  margin-bottom: 20px;
  padding: 10px;
  text-align: center;

  &.message--error {
    background-color: $main-red-20;
    border: 1px solid $main-red;
    color: $main-red;
  }
}

.Toastify__toast-container {
  z-index: 100000 !important;
}

.uk-alert-primary {
  &.uk-alert {
    background-color: white;
    border: 2px solid $openbroker-primary;
    border-radius: 4px;
    font-weight: bold;
    padding: 20px;
  }
}

.global-message {
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 4px;

  &.info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
  }

  &.warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
  }

  &.danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
  }

  &.xs {
    font-size: 10px;
  }
}

.no-global-message {
  .global-message-container {
    display: none;
  }
}


.ie-alert {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  background-color: $main-orange;
  color: $white;
  width: 100%;
  min-height: 100px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  transform: translateY(100%);
  transition: transform .2s ease-in;

  &.show {
    transform: translateY(0);
  }

  a, a:visited, a:hover {
    color: $white;
    text-decoration: underline;
  }

  .icon {
    font-size: 30px;
  }

  h5, p {
    margin: 0;
  }

  h5 {
    color: $white;
    margin-bottom: 10px;
  }

  .text {
    padding: 0 20px;
    width: 100%;
  }

  .ie-small-alert {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 0;
    transform: translateY(-100%);
    background-color: $main-orange;
    padding: 5px 10px;
    font-size: 10px;
    left: 0;

    .icon {
      font-size: 12px;
      color: $white;
      margin-right: 10px;
    }
  }

  .other-browser-button a {
    color: $white;
    border: 1px solid $white;
    padding: 10px 20px;
    white-space: nowrap;
    border-radius: 5px;
    text-decoration: none;

    &:hover {
      background-color: $white;
      color: $main-orange;
    }
  }

  .close-alert {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
    background: transparent;
    border: 0;
    outline: none;
    color: $white;

    .icon {
      font-size: 12px;
      color: $white;
    }
  }
}
