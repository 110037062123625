.quote-container {
  padding: 20px;
  background-color: $light-gray;
  border-radius: 10px;
}

.quote {
  position: relative;
  padding-left: 1em;
  font-style: normal;
  border-left: 0.2em solid lighten($dark-gray, 40%);
  font-family: 'Roboto', serif;
  font-size: 14px;
  line-height: 14px;
  font-weight: 100;

  &:before, &:after {
    content: '\201C';
    font-family: 'Roboto', serif;
    color: lighten($dark-gray, 40%);
  }

  &:after {
    content: '\201D';
  }
}
