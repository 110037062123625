$integration-size: 150px;

.product-card-list {
  display: flex;
  flex-wrap: wrap;

  .product-card-item {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  &.centered {
    justify-content: center;

    .product-card-item {
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}

.product-card-item {
  width: $integration-size;
  height: $integration-size;
  border: 1px solid #E3E8EC;
  position: relative;
  border-radius: 5px;
  cursor: pointer;

  img {
    position: absolute;
    top: calc(50% - 20px);
    left: 50%;
    transform: translate(-50%, -50%);

    &.greyscale {
      filter: grayscale(1);
    }
  }

  .product-card-name {
    border-top: 1px solid #E3E8EC;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    font-weight: bold;
  }

  &.selected {
    border-color: $openBrokerBlue;
    position: relative;

    .product-card-name {
      background-color: $openBrokerBlue;
      color: #fff;
    }

    i.icon-selected {
      position: absolute;
      right: 7px;
      top: 7px;
      background-color: $openBrokerBlue;
      color: #fff;
      border-radius: 50%;
      padding: 1px;
    }

    &:hover {
      .product-card-name {
        color: #fff;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;

    &.selected {
      border-color: $gray;

      .product-card-name {
        background-color: $gray;
      }
    }

    &:hover {
      cursor: not-allowed;
      border-color: $directory-border !important;

      .product-card-name {
        color: $gray;
      }
    }

    i.icon-selected {
      background-color: $gray !important;
    }
  }

  &:hover {
    cursor: pointer;
    border-color: $openbroker-secondary;

    .product-card-name {
      color: $openbroker-secondary;
    }
  }

  .unavailable-message {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding: 10px 5px;
    color: $gray;
    z-index: 10;
    font-style: italic;
    text-align: center;
  }
}


.integration-status {
  position: absolute;
  top: 5px;
  right: 5px;

  &:before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
  }


  &.pending {
    color: $openbroker-warning;

    &:before {
      background-color: $openbroker-warning;
    }
  }

  &.verified {
    color: $openbroker-primary;

    &:before {
      background-color: $openbroker-primary;
    }
  }

  &.rejected {
    color: $openbroker-error;

    &:before {
      background-color: $openbroker-error;
    }
  }
}
