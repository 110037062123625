.object-card {
  background-color: white;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .4);
  padding: 20px 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  transition: background-color .2s ease-in-out;

  &:hover,
  &.active,
  &.drawer-open {
    cursor: pointer;
    background-color: $openbroker-secondary-light;
  }

  &.no-hover {
    cursor: default;
    background-color: white;
  }

  .card-description {
    padding-left: 10px;
    flex: 1;

    p:not(.object-name) {
      font-size: 12px;
      color: $openbroker-gray;
      margin-top: 0;
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .object-name {
    font-size: 14px;
    color: $openbroker-secondary;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .object-name,
  .ob-title {
    margin: 0 0 5px 0;
  }
}


.car-resume-card {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .part {
    width: 25%;
    margin: 0 0 10px 0;
  }
}


.car-read-only {
  margin-bottom: 20px;

  .car-form {
    width: 70%;
    margin-right: auto;

    .inline-fields {
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }

  .uk-section-muted {
    margin-left: 20px;
    width: 28%;
    flex-direction: column;

    .part {
      width: 100%;
    }
  }

}

.part {
  padding: 0 10px;
  margin: 0 0 10px;

  .part-title {
    margin: 0 0 5px 0;
    font-weight: bold;
  }

  .part-description {
    margin: 0;
  }
}

.horizontal-list {
  @extend .uk-flex;
  @extend .uk-flex-wrap;

  .part {
    width: 15%;
  }
}
