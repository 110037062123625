.crop-zone {

}

.cropper-actions {
  padding: 10px 0;
  user-select: none;

  .button {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}
