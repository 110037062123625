/*--------------------------------

Openbroker Web Font
Generated using nucleoapp.com

-------------------------------- */
@font-face {
  font-family: 'Openbroker';
  src: url('../fonts/Openbroker.eot');
  src: url('../fonts/Openbroker.eot') format('embedded-opentype'), url('../fonts/Openbroker.woff2') format('woff2'), url('../fonts/Openbroker.woff') format('woff'), url('../fonts/Openbroker.ttf') format('truetype'), url('../fonts/Openbroker.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
/*------------------------
	base class definition
-------------------------*/
.icon {
  display: inline-block;
  font: normal normal normal 1em/1 'Openbroker';
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*------------------------
  change icon size
-------------------------*/
/* relative units */
.icon-sm {
  font-size: 0.8em;
}
.icon-lg {
  font-size: 1.2em;
}
/* absolute units */
.icon-16 {
  font-size: 16px;
}
.icon-32 {
  font-size: 32px;
}
/*----------------------------------
  add a square/circle background
-----------------------------------*/
.icon-bg-square,
.icon-bg-circle {
  padding: 0.35em;
  background-color: #eee;
}
.icon-bg-circle {
  border-radius: 50%;
}
/*------------------------------------
  use icons as list item markers
-------------------------------------*/
.icon-ul {
  padding-left: 0;
  list-style-type: none;
}
.icon-ul > li {
  display: flex;
  align-items: flex-start;
  line-height: 1.4;
}
.icon-ul > li > .icon {
  margin-right: 0.4em;
  line-height: inherit;
}
/*------------------------
  spinning icons
-------------------------*/
.icon-is-spinning {
  -webkit-animation: icon-spin 2s infinite linear;
  -moz-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes icon-spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*------------------------
  rotated/flipped icons
-------------------------*/
.icon-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.icon-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.icon-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.icon-flip-y {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.icon-flip-x {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1);
}
/*------------------------
	icons
-------------------------*/

.ob-icon-art::before {
  content: "\ea02";
}

.ob-icon-links::before {
  content: "\ea03";
}

.ob-icon-about-account::before {
  content: "\ea04";
}

.ob-icon-credit-card::before {
  content: "\ea05";
}

.ob-icon-jewellery::before {
  content: "\ea07";
}

.ob-icon-balance::before {
  content: "\ea08";
}

.ob-icon-value::before {
  content: "\ea09";
}

.ob-icon-savings::before {
  content: "\ea0a";
}

.ob-icon-legal::before {
  content: "\ea0b";
}

.ob-icon-api::before {
  content: "\ea0c";
}

.ob-icon-e-reputation::before {
  content: "\ea0d";
}

.ob-icon-archive::before {
  content: "\ea0e";
}

.ob-icon-car::before {
  content: "\ea0f";
}

.ob-icon-van::before {
  content: "\ea10";
}

.ob-icon-driver::before {
  content: "\ea11";
}

.ob-icon-bike::before {
  content: "\ea12";
}

.ob-icon-email::before {
  content: "\ea13";
}

.ob-icon-health::before {
  content: "\ea14";
}

.ob-icon-application-settings::before {
  content: "\ea15";
}

.ob-icon-library::before {
  content: "\ea16";
}

.ob-icon-members::before {
  content: "\ea17";
}

.ob-icon-user::before {
  content: "\ea18";
}

.ob-icon-download-customer::before {
  content: "\ea19";
}

.ob-icon-upload-customer::before {
  content: "\ea1a";
}

.ob-icon-reference::before {
  content: "\ea1b";
}

.ob-icon-language::before {
  content: "\ea1c";
}

.ob-icon-motorbike::before {
  content: "\ea1d";
}

.ob-icon-warning::before {
  content: "\ea1e";
}

.ob-icon-arrow-previous::before {
  content: "\ea1f";
}

.ob-icon-arrow-down::before {
  content: "\ea20";
}

.ob-icon-arrow-next::before {
  content: "\ea21";
}

.ob-icon-arrow-up::before {
  content: "\ea22";
}

.ob-icon-arrow-left::before {
  content: "\ea23";
}

.ob-icon-arrow-right::before {
  content: "\ea24";
}

.ob-icon-delete::before {
  content: "\ea25";
}

.ob-icon-help::before {
  content: "\ea26";
}

.ob-icon-notification::before {
  content: "\ea27";
}

.ob-icon-history::before {
  content: "\ea28";
}

.ob-icon-settings::before {
  content: "\ea29";
}

.ob-icon-add::before {
  content: "\ea2a";
}

.ob-icon-check::before {
  content: "\ea2b";
}

.ob-icon-construction::before {
  content: "\ea2c";
}

.ob-icon-house::before {
  content: "\ea2d";
}

.ob-icon-housing::before {
  content: "\ea2e";
}

.ob-icon-building::before {
  content: "\ea2f";
}

.ob-icon-birthday::before {
  content: "\ea30";
}

.ob-icon-multiple-actions-setting::before {
  content: "\ea31";
}

.ob-icon-apartment::before {
  content: "\ea32";
}

.ob-icon-heritage::before {
  content: "\ea33";
}

.ob-icon-content::before {
  content: "\ea34";
}

.ob-icon-goods::before {
  content: "\ea35";
}

.ob-icon-accident::before {
  content: "\ea36";
}

.ob-icon-life::before {
  content: "\ea37";
}

.ob-icon-location::before {
  content: "\ea38";
}

.ob-icon-cancellation::before {
  content: "\ea39";
}

.ob-icon-travel-2::before {
  content: "\ea3a";
}

.ob-icon-luggage::before {
  content: "\ea3b";
}

.ob-icon-travel::before {
  content: "\ea3c";
}

.ob-icon-mobile-phone::before {
  content: "\ea3d";
}

.ob-icon-phone::before {
  content: "\ea3e";
}

.ob-icon-menu::before {
  content: "\ea3f";
}

.ob-icon-employees::before {
  content: "\ea40";
}

.ob-icon-electronic-device::before {
  content: "\ea41";
}

.ob-icon-company::before {
  content: "\ea42";
}

.ob-icon-more-vert::before {
  content: "\ea43";
}

.ob-icon-block::before {
  content: "\ea44";
}

.ob-icon-other::before {
  content: "\ea45";
}

.ob-icon-heritage-savings::before {
  content: "\ea46";
}

.ob-icon-company-building::before {
  content: "\ea47";
}

.ob-icon-responsibility::before {
  content: "\ea48";
}

.ob-icon-mobility::before {
  content: "\ea49";
}

.ob-icon-customer-pdf::before {
  content: "\ea4a";
}

.ob-icon-need-analysis::before {
  content: "\ea4b";
}

.ob-icon-activity-report::before {
  content: "\ea4c";
}

.ob-icon-folder::before {
  content: "\ea4d";
}

.ob-icon-send-to-customer::before {
  content: "\ea4e";
}

.ob-icon-actions::before {
  content: "\ea4f";
}

.ob-icon-save::before {
  content: "\ea50";
}

.ob-icon-integrations::before {
  content: "\ea51";
}

.ob-icon-see::before {
  content: "\ea52";
}

.ob-icon-edit::before {
  content: "\ea53";
}

.ob-icon-file::before {
  content: "\ea54";
}

.ob-icon-internet::before {
  content: "\ea55";
}

.ob-icon-lock::before {
  content: "\ea56";
}

.ob-icon-copy-paste::before {
  content: "\ea57";
}

.ob-icon-download::before {
  content: "\ea58";
}

.ob-icon-answer::before {
  content: "\ea5a";
}

.ob-icon-column-1::before {
  content: "\ea5b";
}

.ob-icon-column-2::before {
  content: "\ea5c";
}

.ob-icon-column-3::before {
  content: "\ea5d";
}

.ob-icon-image-block::before {
  content: "\ea5e";
}

.ob-icon-text-block::before {
  content: "\ea5f";
}

.ob-icon-pdf::before {
  content: "\ea60";
}

.ob-icon-exclamation::before {
  content: "\ea61";
}

.ob-icon-information::before {
  content: "\ea62";
}

.ob-icon-smart-product::before {
  content: "\ea63";
}

.ob-icon-list::before {
  content: "\ea64";
}

.ob-icon-logout::before {
  content: "\ea65";
}

.ob-icon-profile::before {
  content: "\ea66";
}

.ob-icon-request::before {
  content: "\ea67";
}

.ob-icon-close::before {
  content: "\ea68";
}

.ob-icon-quick-tarification::before {
  content: "\ea69";
}

.ob-icon-print::before {
  content: "\ea6a";
}

.ob-icon-star::before {
  content: "\ea6d";
}

.ob-icon-video::before {
  content: "\ea6e";
}

.ob-icon-refresh::before {
  content: "\ea6f";
}

.ob-icon-loading::before {
  content: "\ea70";
}

.ob-icon-eye::before {
  content: "\ea72";
}

.ob-icon-search::before {
  content: "\ea73";
}

.ob-icon-keyboard-arrow-left::before {
  content: "\ea75";
}

.ob-icon-keyboard-arrow-right::before {
  content: "\ea76";
}

.ob-icon-arrow-sort-down::before {
  content: "\ea77";
}

.ob-icon-arrow-sort-up::before {
  content: "\ea78";
}

.ob-icon-arrow-sort::before {
  content: "\ea79";
}

.ob-icon-check-circle::before {
  content: "\ea7a";
}

.ob-icon-uncheck-circle::before {
  content: "\ea7b";
}

.ob-icon-eye-off::before {
  content: "\ea7c";
}

.ob-icon-calendar::before {
  content: "\ea7d";
}

.ob-icon-sales::before {
  content: "\ea7e";
}

.ob-icon-roles::before {
  content: "\ea7f";
}

.ob-icon-docx::before {
  content: "\ea80";
}

.ob-icon-coming-soon::before {
  content: "\ea82";
}

.ob-icon-discount::before {
  content: "\ea84";
}

.ob-icon-assistance::before {
  content: "\ea85";
}

.ob-icon-protection::before {
  content: "\ea86";
}

.ob-icon-product-parameters::before {
  content: "\ea87";
}

.ob-icon-contract::before {
  content: "\ea88";
}

.ob-icon-business-deal-handshake-circle::before {
  content: "\ea89";
}

.ob-icon-evaluation::before {
  content: "\ea8a";
}

.ob-icon-offer::before {
  content: "\ea8b";
}

.ob-icon-send::before {
  content: "\ea8c";
}

.ob-icon-house-fire::before {
  content: "\ea8d";
}

.ob-icon-24h::before {
  content: "\ea8e";
}

.ob-icon-business::before {
  content: "\ea8f";
}

