$inverse-global-color-mode: none;
$drop-width: auto;

@import "uikit-theme/colors";
@import "uikit-theme/offcanvas";
@import "~uikit/src/scss/variables-theme";
@import "~uikit/src/scss/mixins-theme";


@import "~uikit/src/scss/uikit-theme.scss";

@import "~uikit/src/scss/uikit.scss";

.uk-button {
  text-transform: none;
}

.uk-tab > .uk-active > a {
  border-color: $main-blue;
}

.uk-alert {
  &.align-middle {
    display: flex;
  }

  &.uk-alert--small {
    padding: 5px 10px;
    font-size: 12px;

    .material-icons {
      font-size: 14px;
    }
  }
}

.uk-list--small-margin {
  li {
    margin-bottom: 5px;
  }
}

.uk-alert-disabled {
  background-color: $light-gray;
}

ins {
  background-color: transparent;
  text-decoration: underline;
  color: $default-color;
}

.uk-description-list {
  dt {
    font-weight: lighter;
    color: $gray;
  }
}

.uk-offcanvas-bar {
  padding: 0 !important;
}

.uk-pagination {
  li, a {
    display: flex;
    align-items: center;
  }

  .ob-icon {
    font-size: 12px;
  }
}

.uk-dropdown {
  padding: 20px;

  .uk-nav {
    li {
      margin-bottom: 10px;
    }

    li:last-child {
      margin-bottom: 0;
    }
  }
}

.uk-checkbox {
  &:disabled {
    background-color: $gray;
  }
}
