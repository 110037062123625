// Settings

$brokerage-nav-item-size: 80px;

// Style

.has--environment-banner,
[data-env-banner="has--environment-banner"] {
  .brokerage-navigation {
    top: $main-menu-height + $banner-height;
  }
}

.brokerage-navigation {
  display: flex;
  flex-flow: column;
  list-style: none;
  width: $brokerage-nav-item-size;
  background-color: $dark-blue;
  top: 75px;
  bottom: 0;
  min-height: 100%;
  padding: 0;

  li {
    width: $brokerage-nav-item-size;
    height: $brokerage-nav-item-size;
    display: flex;
    align-items: center;
  }

  .nav-item {
    color: $light-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 10px;
    height: 100%;
    width: 100%;

    .ob-icon {
      font-size: 24px;
      display: block;
      margin-bottom: 10px;
    }

    &.active,
    &:hover {
      color: $dark-blue;
      background-color: white;
      text-decoration: none;
    }
  }
}

.brokerage-container {
  margin: -20px;
  display: flex;
  min-height: calc(100vh - 75px);

  .brokerage-content {
    flex: 1;
    padding: 20px;
  }
}

.brokerage-section {
  background-color: $white;
  padding: 20px;
}

.brokerage-section-title {
  font-size: 18px;
  font-weight: bold;
  color: $main-blue;
  margin: 0;
}

.brokerage-section-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .brokerage-section-title {
    flex: 1;
    margin-bottom: 0;
  }
}

.table--members {
  .member-line {
    select {
      margin: 0;
    }
  }

  .invitation-status {
    .material-icons {
      font-size: 18px;
      top: 5px;
      padding-right: 10px;
    }
  }
}
