.dropzone-container {
  background-color: $smoke-gray;
  border: 1px dashed $gray;
  border-radius: 5px;
  color: $gray;
  cursor: pointer;
  margin-bottom: 20px;
  min-height: 100px;
  padding: 10px;
  position: relative;

  &:hover {
    border-color: $main-blue;
    color: $main-blue;
  }

  & > div {
    align-items: center;
  }

  .dropzone-placeholder {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  .dropzone-hover {
    background-color: rgba(0, 0, 0, 0.4);
    bottom: 0;
    color: $white;
    left: 0;
    line-height: 130px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;

    .material-icons {
      font-size: 50px;
    }
  }

  &.center-loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input[type="file"] {
    margin: 0;
  }
}

.file-list {
  display: flex;
  flex-wrap: wrap;
}

.file-upload-action {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
