.main-menu {
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: $openbroker-secondary;
  @include main-gradient();
  height: $main-menu-height;
  z-index: 900;

  .subscription-warning {
    margin-right: 20px;
  }

  .logo img {
    height: 40px;
  }

  .menu-right {
    margin-left: auto;
  }

  .menu {
    margin-left: 20px;

    a {
      &,
      &:hover,
      &:visited,
      &:focus {
        outline: none;
        color: white;
      }

      &:hover {
        font-weight: bold;
      }
    }

    &.main {
      display: flex;

      li {
        margin-right: 20px;
      }
    }
  }

  li {
    display: flex;
    align-items: center;
  }
}

.menu-right {
  & > * {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  .icon-link {
    color: white;
  }
}

.user-menu,
.menu-right {
  display: flex;
  align-items: center;

  .ob-icon {
    font-size: 20px;
  }

  .user-menu-button {

    .profile-icon {
      font-size: 20px;
      margin: 0;
    }
  }
}

.user-menu-button {
  display: flex;
  align-items: center;
  background: none;
  border: 0;
  outline: none;
  padding: 0;
  color: white;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  .ob-icon {
    margin-left: 10px;
    font-size: 14px;
  }

  .user-menu-dropdown-icon {
    transform: rotate(-90deg);
    transition: all .2s ease-in-out;
  }

  &:hover {
    .user-menu-dropdown-icon {
      transform: rotate(0);
    }
  }
}

.user-menu-link {
  display: flex !important;
  align-items: center;

  .ob-icon {
    margin-right: 10px;
  }
}

.user-name {
  font-size: 12px;
}

.logo {
  &.logo-80 {
    img {
      height: 80px;
    }
  }
}

#notifications-dropdown {
  margin-right: 0;
}
