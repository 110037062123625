.subscription-plan-preview {
  thead {
    th {
      background-color: $openbroker-primary;
      color: white;

      &.quantity {
        text-align: center;
      }

      &.price {
        text-align: right;
      }
    }
  }

  tr {
    td {
      &.quantity {
        text-align: center;
      }

      &.price {
        text-align: right;
      }
    }
  }

}
