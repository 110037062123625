.range-slider-container {
  padding: 0 20px;
}

.range-slider {
  &.ui-widget {
    &.ui-widget-content {
      border: 0;
    }

    .ui-slider-handle {
      background-color: $main-green;
      border-color: $white;
      border-radius: 50%;
      box-shadow: 0 1px 3px rgba(0,0,0,0.4);
      height: 25px;
      top: -10px;
      width: 25px;

      &::after {
        bottom: -2px;
        color: $main-blue;
        content: attr(selected-value);
        font-size: 12px;
        font-weight: bold;
        position: absolute;
        transform: translate(-15%, 100%);
        white-space: nowrap;
      }

      &.ui-state-active {

        &::after {
          color: $main-blue;
        }
      }

      &:focus {
        outline: none;
      }
    }

    .ui-slider-range {
      background-color: $main-green;
    }
  }

  &.ui-slider {
    background-color: $main-blue;
    border: 0;
    border-radius: 10px;
    height: 5px;
    margin-bottom: 20px;
  }
}

.range-slider-value {
  color: $main-blue;
  font-weight: bold;

  &::after,
  &::before {
    content: ' ';
  }
}

.range-slider-information {
  color: $main-blue;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.range-slider-values {
  color: $main-blue;
  margin-bottom: 20px;
}

.ui-slider-horizontal {
  .ui-slider-handle {
    &:last-child {
      &:after {
        bottom: 5px;
        transform: translate(-65%, -100%);
      }
    }
  }
}