.ob-title {
  &.error {
    color: $openbroker-error;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  color: $main-blue;
}

h1 {
  font-size: 20px;
}

h2 {
  font-size: 18px;
}

h3 {
  font-size: 16px;
}

.small-title-dark {
  font-size: 14px;
  font-weight: bold;
  color: $openbroker-default-text;
}

.default-text-title {
  font-weight: normal;
  color: $openbroker-default-text;
  font-size: 0.9rem;
}
