.error-container {
  max-width: 500px;
  background: white;
  padding: 40px;
  text-align: center;
  margin: 40px auto;
  @include default-shadow();

  img {
    width: 50%;
  }
}
