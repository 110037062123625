$lead: lighten(#67C195, 10%);
$active: lighten(#81e065, 10%);
$draft-lead: lighten(#D5D98F, 10%);
$tarification-frozen: lighten(#559CB3, 10%);
$backoffice: lighten(#f79057, 10%);
$return-from-backoffice: lighten(#f1d618, 10%);
$archived: $gray;
//$offer: #B731A1;
$offer: lighten(#7C3AAE, 10%);
$rejected_by_backoffice: lighten(#C1274C, 10%);
.uk-label-archived {
  background-color: $gray;
}

.ob-type {
  @extend .uk-label;
  background-color: $gray;
  color: $white;
  font-size: 10px;
}

.ob-status {
  @extend .uk-label;

  &.lead {
    background-color: $lead;
  }

  &.active {
    background-color: $active;
  }

  &.draft_lead {
    background-color: $draft-lead;
  }

  &.tarification_frozen {
    background-color: $tarification-frozen;
  }

  &.backoffice {
    background-color: $backoffice;
  }

  &.returned_from_backoffice {
    background-color: $return-from-backoffice;
  }

  &.archived {
    background-color: $archived;
  }

  &.offer {
    background-color: $offer;
  }

  &.rejected_by_backoffice {
    background-color: $rejected_by_backoffice;
  }
}
