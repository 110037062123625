.StripeElement {
  border: 1px solid $openbroker-secondary;
  padding: 0.5rem;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  background-color: white;
}

.StripeElement--focus {
  outline: none;
  border-color: #8a8a8a;
  background-color: #fefefe;
  box-shadow: 0 0 5px #cacaca;
  -webkit-transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

.StripeElement--invalid {
  border-color: #a94442;
}

.credit-card-form {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 20px;
  max-width: 300px;
  background-image: linear-gradient(135deg, #FDEB71 10%, #F8B700 100%);

  label {
    text-transform: uppercase;
    font-size: 10px;
    text-shadow: 0 1px 1px white;
    color: #484848;
  }

  input[readonly] {
    background: transparent;
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }

  &.error {
    border-color: $openbroker-error;

    .error-hint {
      font-size: 10px;
      line-height: 10px;
    }
  }

  .card-number,
  .validity-cvc {
    margin-bottom: 1rem;
  }

  .number-logo {
    display: flex;
    align-items: center;
  }

  .number-input {
    flex: 1;
    max-width: 300px;
    margin-right: 20px;
    margin-bottom: 0;
  }

  .validity-cvc {
    display: flex;

    .date {
      margin-right: 20px;
    }

    .cvc {
      .StripeElement {
        max-width: 50px;
      }

      min-width: 50px;
    }
  }

  &.readonly {
    overflow: hidden;
    position: relative;

    &:hover {
      cursor: pointer;

      .edit-hover {
        opacity: 1;
        transition: all .2s ease-in-out;
      }
    }

    .edit-hover {
      opacity: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, .4);

      .material-icons {
        width: 50px;
        height: 50px;
        background-color: white;
        border-radius: 50%;
        text-align: center;
        line-height: 50px;
        color: $openbroker-secondary;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

#credit_card_form {
  .credit-card {
    margin: 0 auto;
  }
}
