.be-spin {
  animation: be-spin 1s infinite linear;
}

@keyframes be-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
