.tarification-resume {
  .title {
    font-weight: bold;
    font-size: 14px;
    display: flex;
    align-items: center;

    .product-logo {
      margin-right: 20px;
    }
  }
}
