html {
  font-size: 87.5%;
  color: $openbroker-default-text;
}

body {
  font-size: 12px;
  font-size: .9rem;

  &,
  & *:not(.ob-icon):not(.material-icons) {
    font-family: 'Noto Sans', sans-serif;
  }
}

.icon-text {
  display: flex !important;
  align-items: center;

  .icon {
    margin-right: 10px;
  }
}

.justify-center {
  justify-content: center;
}

.text-icon {
  display: flex !important;
  align-items: center;

  .icon {
    margin-left: 10px;
  }
}

.help-text {
  display: block;
  margin: 10px 0;

  .icon {
    font-size: 18px;
    color: $openbroker-primary;
  }
}

.icon {
  font-size: 16px;
  position: relative;
}

.no-item {
  font-size: 14px;
  font-style: italic;
  color: $openbroker-gray;
}

.line-through {
  text-decoration: line-through;
}
