.json-resume {
  .json-item {
    margin: 0 0 10px 0;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: 100%;

    .label {
      width: 100%;
      font-weight: bold;

      &.wrap {

      }
    }

    .value {
      display: flex;
      align-items: center;
    }
  }
}

@include respond-above(lg) {
  .json-resume {
    .json-item {
      &.wrap .label {
        margin-bottom: 10px;
      }

      &.wrap .value {
        width: 100%;
      }
    }

    .json-item:not(.wrap) {
      margin-bottom: 5px;
      flex-flow: row;
      align-items: center;

      .label {
        width: 45%;
        margin-right: 20px;
      }
    }
  }
}
