.contract-request-resume {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

@include respond-above(sm) {
  .contract-request-resume {
    flex-flow: row;
    flex-wrap: wrap;
    width: 100%;

    .resume-part {
      box-sizing: border-box;
      width: 50%;
      padding-right: 20px;
    }
  }
}

@include respond-above(lg) {
  .contract-request-resume {
    .resume-part {
      width: 33%;
    }
  }
}
