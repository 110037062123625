.session-container {
  display: flex;
  flex-flow: column;
  flex-direction: column-reverse;
}

.no-account {
  background-color: white;
  text-align: center;

  .title {
    justify-content: center;
  }

  .inner {
    width: 100%;
    padding: 20px;
    height: 100vh;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }

  .LanguageSelectorContainer {
    position: absolute;
    top: 20px;
    right: 20px;
    border-color: $openbroker-secondary;

    select {
      color: $openbroker-secondary;

      option {
        color: $openbroker-secondary;
      }
    }
  }
}

@media screen and (min-width: 720px) {
  .session-container {
    flex-flow: row;
  }
}
