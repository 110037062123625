.progress-bar-container {
  border: 1px solid $main-blue;
  border-radius: 5px;
  height: 10px;
  margin-top: 5px;
  position: relative;

  .progress-bar-inner-container {
    border-radius: 5px;
    bottom: 1px;
    left: 1px;
    overflow: hidden;
    position: absolute;
    right: 1px;
    top: 1px;

    .progress {
      background-color: $main-green;
      bottom: 0;
      left: 0;
      position: absolute;
      top: 0;
    }
  }
}