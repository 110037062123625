.banner {
  height: $banner-height;
  top: 0;
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  font-size: 10px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  background-image: repeating-linear-gradient(135deg, transparent, transparent 35px, rgba(255, 255, 255, .2) 35px, rgba(255, 255, 255, .2) 70px);
  padding: 0 20px;

  &.development {
    background-color: $openbroker-warning;
    color: #2b1c0a;
  }

  &.staging {
    background-color: #C2F9BB;
    color: #023618;
  }

  &.validation {
    background-color: #009EB7;
    color: white;
  }

  &.offline {
    background-color: $main-red;
    background-image: none;
    color: $white;
    height: 50px;
    justify-content: center;
  }
}
